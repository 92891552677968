
            .marquee {
                height: 900px;
				width: 100%;
                overflow: hidden;
                position: relative;
                background: #fefefe;
                color: #333;
                border: 1px solid #4a4a4a;
            }
            .table-container {
                overflow-y: auto;
                max-height: 900px;
              }

              .react-bootstrap-table th {
                position: sticky;
                top: -1px;
                background-color: #fff;
                opacity: "100%";
                z-index: 2;
              }

            .table td {
                border-top: 0px; 
            }
            .table-bordered td {
                border-top: 0px !important;
                border-bottom: 0px !important;
            }
            
            .marquee table tbody {
                --position: absolute;
                width: 100%;
                height: 100%;
                line-height: 50px;
                text-align: center;
                transform: translateY(100%);
                animation: scroll-top 400s linear infinite;
            }

@keyframes scroll-top {
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
} 